import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import {
  FaTwitter as Twitter,
  FaGithub as Github,
  FaLinkedin as LinkedIn,
  FaEnvelope as Mail,
  FaStackOverflow as StackOverflow,
} from 'react-icons/fa';

const IconGroup = styled.div`
  padding: 10px 0;
  text-align: center;
  font-size: 2.3rem;

  a {
    margin: 0 0.6rem;
    text-decoration: none;
    display: inline-block;
  }

  svg {
    transition: 0.2s;
    display: block;
    &:hover {
      transform: scale(1.5);
      color: #ff3a79;
    }
  }
`;

export default function SocialIcons() {
  const data = useStaticQuery(graphql`
    query MyQuery {
      site {
        siteMetadata {
          socialLinks {
            name
            url
          }
        }
      }
    }
  `);

  return (
    <IconGroup>
      {data.site.siteMetadata.socialLinks.map(({ name, url }, index) => (
        <OutboundLink
          href={url}
          key={index}
          target='_blank'
          aria-label={`${name}`}
          rel='noopener noreferrer'
        >
          <Icon name={name} />
        </OutboundLink>
      ))}
    </IconGroup>
  );
}

const Icon = ({ name }) => {
  switch (name) {
    case 'Github':
      return <Github />;
    case 'Twitter':
      return <Twitter />;
    case 'LinkedIn':
      return <LinkedIn />;
    case 'Mail':
      return <Mail />;
    case 'StackOverflow':
      return <StackOverflow />;
    default:
      break;
  }
};
