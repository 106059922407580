import React from 'react';
import PageGrid from '../components/Responsive/PageGrid';
import Layout from '../components/Layout';
import ProfileImage from '../images/profileImage';
import SocialIcons from '../components/SEO/SocialIcons';
import Heading from '../components/Typography/Heading';
import Description from '../components/Typography/Description';
import SEO from '../components/SEO/Seo';
import ThemeToggle from '../components/Layout/ThemeToggle';
import { graphql } from 'gatsby';

export default function index({ data }) {
  const body = data.markdownRemark.rawMarkdownBody;
  const heading = data.markdownRemark.frontmatter.heading;
  const title = data.markdownRemark.frontmatter.title;
  return (
    <Layout>
      <SEO title={title} />
      <ThemeToggle />
      <PageGrid>
        <div className='grid-container'>
          <div className='grid-item'>
            <Heading>{heading}</Heading>
            <Description>{body}</Description>
            <SocialIcons />
          </div>
          <div className='grid-item'>
            <ProfileImage />
          </div>
        </div>
      </PageGrid>
    </Layout>
  );
}

export const indexQuery = graphql`
  {
    markdownRemark(frontmatter: { page: { eq: "Index" } }) {
      rawMarkdownBody
      frontmatter {
        heading
        title
      }
    }
  }
`;
