import React from 'react';
import Container from '../Responsive/Container';
import Footer from '../SEO/Footer';
import './layout.css';

export default function Layout({ children }) {
  return (
    <Container>
      <main style={{ margin: 'auto' }}>{children}</main>
      <Footer></Footer>
    </Container>
  );
}
