import React from 'react';
import { ThemeToggler } from 'gatsby-plugin-dark-mode';
import styled from 'styled-components';
import moon from '../../images/moon.png';
import sun from '../../images/sun.png';

export default function ThemeToggle() {
  return (
    <ThemeToggler>
      {({ theme, toggleTheme }) => {
        if (theme == null) {
          return null;
        }
        return (
          <Label>
            <Toggle
              type='checkbox'
              checked={theme === 'dark'}
              onChange={(e) => {
                toggleTheme(e.target.checked ? 'dark' : 'light');
              }}
            />
            <Moon src={moon} />
            <Sun src={sun} />
          </Label>
        );
      }}
    </ThemeToggler>
  );
}

const Moon = styled.img.attrs((props) => ({
  src: props.src,
  alt: '',
}))`
  position: absolute;
  top: 5px;
  right: 5px;
  width: 17px;
  height: 17px;
  pointer-events: none;
`;

const Sun = styled.img.attrs((props) => ({
  src: props.src,
  alt: '',
}))`
  position: absolute;
  top: 5px;
  left: 5px;
  width: 17px;
  height: 17px;
  pointer-events: none;
`;

const Toggle = styled.input.attrs((props) => ({
  type: 'checkbox',
  checked: props.checked,
  'aria-label': 'Switch between Light and Dark mode',
}))`
  position: relative;
  cursor: pointer;
  display: block;
  width: 50px;
  height: 27px;
  -webkit-appearance: none;
  background-color: black;
  border-radius: 20px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  transition: 0.5s;
  margin: 0;

  :checked {
    :before {
      left: 26px;
    }
  }

  :before {
    content: '';
    position: absolute;
    width: 17px;
    height: 17px;
    border-radius: 20px;
    top: 3px;
    left: 3px;
    background: #ff3a79;
    transform: scale(1.2);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    transition: 0.5s;
    z-index: 1;
  }
`;

const Label = styled.label`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  display: inline;
`;
