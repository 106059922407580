import React from 'react';

export default function Footer() {
  return (
    <footer
      style={{
        padding: '15px',
        textAlign: 'center',
        marginTop: '20px',
        fontWeight: 100,
        fontSize: 14,
      }}
    >
      Copyright &copy; 2020 | Pranjal Jately
    </footer>
  );
}
