import styled from 'styled-components';

const Heading = styled.h1`
  font-weight: ${(props) => props.weight || '900'};
  line-height: ${(props) => props.height || '50px'};
  font-size: ${(props) => props.size || '48px'};
  letter-spacing: 0.05em;
  text-align: center;
  text-shadow: 0 4px 5px rgba(0, 0, 0, 0.25);
`;

export default Heading;
