import styled from 'styled-components';

const PageGrid = styled.div`
  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }

  .grid-item {
    margin-bottom: 20px;
  }

  @media (max-width: 735px) {
    .grid-container {
      grid-template-columns: repeat(1, 1fr);
    }

    .grid-item:nth-of-type(1) {
      order: 2;
    }

    .grid-item:nth-of-type(2) {
      order: 1;
    }
  }
`;

export default PageGrid;
