import styled from 'styled-components';

const Description = styled.p`
  letter-spacing: 0.05em;
  line-height: ${(props) => props.height || '33px'};
  font-size: ${(props) => props.size || '30px'};
  font-weight: 700;
  text-align: center;
  text-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
`;

export default Description;
