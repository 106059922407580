import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

const ResponsiveImage = styled(Img)`
  width: 285px;
  height: 285px;
  border-radius: 85%;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 1);
`;

const Wrapper = styled.figure`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WrappedImg = (props) => <Wrapper>{props.children}</Wrapper>;

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "profile.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <WrappedImg>
      <ResponsiveImage
        fluid={data.placeholderImage.childImageSharp.fluid}
        alt='Pranjal Jately smiling'
      />
    </WrappedImg>
  );
};

export default Image;
